export function isCartFull(product, prizeCount, cart) {
  let status = true;

  if (product.amount < 1) {
    status = false;
  }

  cart.forEach((item) => {
    if (item.id === product.id) {
      if (product.amount < product.prizeAmount + prizeCount) {
        status = false;
      }
    }
  });

  return status;
}
