<template>
  <StoreLayout class="cart-layout">
    <div class="cart">
      <div class="cart__decor cart__decor_1"></div>
      <div class="cart__decor cart__decor_2"></div>

      <div class="cart__container">
        <div class="cart__header">
          <h1 class="cart__title">Корзина</h1>
        </div>
        <div v-if="getCart.length" class="cart__list">
          <div class="cart__list-header">
            <div>Наименование</div>
            <div>Количество</div>
            <div>Стоимость</div>
          </div>
          <div class="cart__list-items">
            <div
              v-for="(item, index) in getCart"
              :key="index"
              class="cart__list-item card-item"
            >
              <div class="card-item__content">
                <div class="card-item__title">{{ item.name }}</div>
                <div class="card-item__count">
                  <button
                    @click="decrement(index)"
                    class="button button-minus"
                  ></button>
                  <div class="button field-score">
                    <span>{{ item.prizeAmount }}</span>
                  </div>
                  <button
                    @click="increment(item)"
                    class="button button-plus"
                  ></button>
                </div>
                <div class="card-item__price">
                  <span>{{ item.price | priceFilter }}</span>
                  <div class="product-coins">
                    <span class="coin">
                      <img
                        src="@/assets/img/pic2/coin.svg"
                        width="15"
                        height="15"
                        alt=""
                      />
                    </span>
                    {{ COINS }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cart__footer">
            <div class="cart__footer-top">
              <div class="cart__comment">
                <textarea
                  placeholder="Комментарий"
                  v-model="comment"
                ></textarea>
              </div>
              <div class="cart__price">
                <div class="cart__price-text">Итого:</div>
                <div class="cart__price-inner">
                  <div class="sum-coins">
                    <span class="coin">
                      <img
                        src="@/assets/img/pic2/coin.svg"
                        width="15"
                        height="15"
                        alt=""
                      />
                    </span>
                    {{ getCartTotalPrice | priceFilter }} {{ COINS }}
                  </div>
                </div>
              </div>
            </div>
            <div class="cart__footer-bottom">
              <button
                :disabled="lock"
                class="cart__button button button-default button_long"
                @click="checkout"
              >
                Отправить заказ
              </button>
              <router-link
                class="cart__history text-history button"
                :to="{ name: 'orders' }"
              >
                История заказов
              </router-link>
            </div>
          </div>
        </div>
        <div v-else class="cart__empty">
          <div class="cart__empty-title">Ваша корзина пуста</div>
          <router-link
            :to="{ name: 'store' }"
            tag="button"
            class="cart__button button button-default button_long"
            @click="checkout"
          >
            Перейти в магазин
          </router-link>
          <router-link
            class="cart__empty-history cart__history text-history button"
            :to="{ name: 'orders' }"
          >
            История заказов
          </router-link>
        </div>
      </div>
    </div>
  </StoreLayout>
</template>

<script>
import StoreLayout from "@/layout/StoreLayout.vue";
import { isCartFull } from "@/utils/store";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    StoreLayout,
  },
  data() {
    return {
      comment: "",
      lock: false,
    };
  },
  computed: {
    ...mapGetters(["getCart", "getCartTotalPrice", "getProfile"]),
  },
  methods: {
    ...mapActions([
      "CART_CHECKOUT",
      "CART_ADD",
      "CART_REMOVE",
      "USER_FETCH",
      "USER_FETCH_NOTIFY",
    ]),
    decrement(index) {
      this.CART_REMOVE(index);
    },
    increment(product) {
      if (isCartFull(product, 1, this.getCart)) {
        this.CART_ADD({
          data: product,
          count: 1,
        });
      }
    },
    checkout() {
      const {
        comment,
        getCart,
        getProfile: { id: userId },
      } = this;

      const orderDetails = getCart.map(({ id, prizeAmount, price }) => {
        return {
          prizeId: id,
          prizeAmount,
          prizePrice: price,
        };
      });

      this.lock = true;

      this.CART_CHECKOUT({
        userId,
        orderDetails,
        comment,
      })
        .then(() => {
          this.$modal.show("message", {
            message: "Заказ успешно оформлен!",
          });
          this.USER_FETCH();
          this.$router.push({ name: "orders" });
          this.USER_FETCH_NOTIFY();
          this.lock = false;
        })
        .catch((error) => {
          this.lock = false;
          this.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  width: 1400px;
  margin: 0 auto -100px;
  &__decor {
    @include bg-decor();
    content: "";
    position: fixed;
    z-index: 0;
    left: 50%;
    &_1 {
      @include aspect-ratio(275, 228);
      bottom: 8.7vh;
      width: 275px;
      background-image: url(~@/assets/img/pic/cart-decor.svg);
      transform: translateX(-50%) translateX(-510px);
    }
    &_2 {
      @include aspect-ratio(368, 235);
      bottom: 7.7vh;
      width: 368px;
      background-image: url(~@/assets/img/pic/cart-decor-2.svg);
      transform: translateX(-50%) translateX(440px);
    }
  }
  &__container {
    position: relative;
    z-index: 2;
    width: 1230px;
    margin: 0 auto;
    padding-bottom: 163px;
  }
  &__header {
    position: relative;
    margin-bottom: 34px;
  }
  &__title {
    font-weight: normal;
    font-size: 40px;
  }
  &__list {
    &-header {
      display: flex;
      padding: 0 30px 0 109px;
      margin-left: -5px;
      margin-bottom: 17px;
      font-weight: bold;
      div {
        flex: 0 0 auto;
        &:nth-child(1) {
          flex: 1 1 auto;
          width: 100%;
          max-width: 500px;
        }
        &:nth-child(2) {
          margin-left: auto;
          min-width: 188px;
        }
        &:nth-child(3) {
          margin-left: auto;
          min-width: 162px;
        }
      }
    }
    &-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  &__footer {
    position: relative;
    margin-top: 14px;
    &-top {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-bottom {
      position: relative;
      margin-top: 17px;
      padding-top: 35px;
      text-align: center;
      &::before {
        @include bg-decor();
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1227px;
        height: 3px;
        background-image: url(~@/assets/img/pic/divider-cart.svg);
      }
    }
  }
  &__comment {
    margin-right: 127px;
    ::placeholder {
      color: #814d2b;
    }
    textarea {
      width: 603px;
      height: 70px;
      border: 2px solid #814d2b;
      border-radius: 6px;
      background-color: #e0c18e;
      color: #814d2b;
      padding: 12px 18px;
      font-weight: bold;
      resize: none;
    }
  }
  &__price {
    position: relative;
    top: -2px;
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 20px;
    &-text {
      position: absolute;
      top: 50%;
      display: block;
      transform: translateY(-60%);
      right: calc(100% + 20px);
    }
    &-inner {
      @include bg-decor();
      font-family: $fontRafale;
      font-weight: bold;
      font-size: 30px;
      width: 291px;
      height: 54px;
      background-image: url(~@/assets/img/pic/field-score-dark-long.svg);
      text-align: center;
    }
  }
  &__button {
    flex: 0 0 auto;
    display: block;
    margin: 0 auto;
  }
  &__history {
    @include clickable();
    display: inline-block;
    margin-top: 36px;
  }
  &__empty {
    padding-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > * {
      max-width: 100%;
    }
    &-title {
      font-family: $fontRafale;
      font-weight: bold;
      font-size: 60px;
      margin-bottom: 30px;
    }
    &-history {
      margin-top: 20px;
    }
  }
}

.card-item {
  display: flex;
  flex-direction: column;
  color: #814d2b;
  &__content {
    @include bg-decor();
    display: flex;
    align-items: center;
    min-height: 122px;
    padding: 20px 30px 20px 109px;
    margin-left: -5px;
    background-image: url(~@/assets/img/pic/field-cart-item.svg);
    background-position: 0 4px;
    &::after {
      content: "";
      display: block;
      width: 0%;
      min-height: inherit;
    }
  }
  &__title {
    @include editable-text();
    flex: 1 1 auto;
    width: 100%;
    max-width: 500px;
    font-family: $fontRafale;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -0.001em;
    color: $fontColor;
  }
  &__count {
    flex: 0 0 auto;
    margin-left: auto;
  }
  &__price {
    flex: 0 0 auto;
    margin-left: auto;
    min-width: 162px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    > span {
      position: relative;
      left: -7px;
      display: block;
      font-family: $fontRafale;
      font-size: 50px;
      margin-top: -7px;
      margin-bottom: -2px;
    }
  }
}
.sum-coins {
  display: flex;
  justify-content: center;
  align-items: center;
  .coin {
    position: relative;
    top: 1px;
  }
}
.product-coins {
  display: flex;
}
.coin {
  width: 21px;
  align-self: center;
  margin-right: 2px;
  img {
    width: 100%;
    display: block;
  }
}
</style>
